import React, { Component } from 'react';
import Header from './Header';
import { Footer } from 'tn-components-library';
import Home from '../home';
import Dashboard from "../dashboard"
import Report from "../report"
import { Route,  Router, Switch,HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import LoginRedirect from '../../container/login';
import UnAuth from '../../container/unauthorizedaccess';
import ResNotFound from '../../container/resourcenotfound';
import { footerUrls, getRoleDetails, isStaffingEnabled } from "../../utils";
import ConsentFrom from '../consentFrom';



const history = createBrowserHistory();

const AuthRoute = ({ component: Component,  ...rest }) => {
    //  Page permission
    const userDetails = {
        Tnp1XEnabled: window.customrole.UserContext.tnpenabled,
        PLAN2x: window.customrole.UserContext.tnp2xenabled,
        Tnr2xEnabled: window.customrole.UserContext.tnr2xenabled,
        CustomRoleEnabled: window.customrole.UserContext.customroleenabled,
        AdminEnabled: window.customrole.UserContext.adminenabled,
        Tnr1xEnabled: window.customrole.UserContext.tnr1xenabled,
        DataFeedEnabled: window.customrole.UserContext.DataFeedEnabled,
        personaID: window.customrole.UserContext.personaID,
        responsibilityIdsCSV: window.customrole.UserContext.responsibilityIdsCSV
    }
    const roles = getRoleDetails(window.customrole.UserContext.geminiroles)
    const isStaffing = isStaffingEnabled(roles)
    if (checkPagePermission(rest.pageName, rest.state)) {
        
        return (<div>
            {rest.pageName !== 'Report' && <Header />}
            <Route {...rest} render={props => (<Component {...props} />)} />
            {rest.pageName !== 'Report' && <div className='custom-role-footer'><Footer roles={roles} staffing={isStaffing} plan={userDetails.PLAN2x} recruit={userDetails.Tnr2xEnabled} customRole={userDetails.CustomRoleEnabled} pageUrl={footerUrls}/></div>}
        </div>);

    } else {
        return (
            <div>
                <Header />
                <Route {...rest} render={props => (
                    <UnAuth {...props} />)} />
                <Footer roles={roles} staffing={isStaffing} plan={userDetails.PLAN2x} recruit={userDetails.Tnr2xEnabled} customRole={userDetails.CustomRoleEnabled} pageUrl={footerUrls}/>
            </div>
        );
    }
};

const checkPagePermission = (pageName, state) => {
    //Check page exists in his accessible feature     
    //Allow home by default

    return state.auth.userObject.isAuthorized;
};

class Root extends Component {

    constructor(props, context) {
        super(props, context);
        this.startHotJarTracking = this.startHotJarTracking.bind(this);
    }

    startHotJarTracking() {
        (function(h,o,t,j,i,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:i,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=', window.customrole.Config.trackingHotJarId);
      }

    componentDidMount () {
        if( window.customrole && window.customrole.UserContext && window.customrole.UserContext.ishotjarenabled && window.customrole.UserContext.isLatestAgreementAccepted){
            this.startHotJarTracking()
        }
    }

    render() {
        let state = this.context.store.getState();
        if (state.auth.userObject && state.auth.userObject.isAuthenticated) {
            return (
                <div>
                    <HashRouter>
                        <div>

                            <div>
                                <Switch>
                                    <AuthRoute pageName="Home" state={state} exact path="/" component={Home} />
                                    <AuthRoute pageName="Dashboard" state={state}  path="/dashboard" component={Dashboard} />
                                    <AuthRoute pageName="Report" state={state} path="/report" component={Report} />
                                    {/*<AuthRoute pageName="Myaccount" state={state} path="/myaccount" component={Account} />*/}
                                    <Route state={state} history={history} component={ResNotFound} />
                                </Switch>
                            </div>
                        </div>
                    </HashRouter>
                    <ConsentFrom enableHotJarTracking={this.startHotJarTracking}/>
                </div>
            )
        } else {
            return (
                <div>
                    <Router history={history}>
                        <div>
                            <Route path='/' component={LoginRedirect} />
                        </div>
                    </Router>
                </div>
            )
        }

    }


}

Root.contextTypes = {
    store: PropTypes.object
}

export default Root;

