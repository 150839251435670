import React, { Component } from "react";
import { ConsentFormModal } from "tn-components-library";
import Config from "../../profiles";
import http from "../../http/http";

class ConsentFrom extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      changeContent: true,
      consentModalOpen: false,
      consentDetails: {},
    };
    this.handleUserSelectedOption = this.handleUserSelectedOption.bind(this);
    this.updateConsentDetails = this.updateConsentDetails.bind(this);
    this.getConsentDetails = this.getConsentDetails.bind(this);
  }

  async getConsentDetails() {
    const consentContentUrl = Config.getAdminServiceURL + `/api/consent/`;
    await Promise.resolve(http.get(consentContentUrl)).then(
      (consentResponse) => {
        if (consentResponse.data && !consentResponse.data.isError) {
          this.setState({
            consentDetails: consentResponse.data.data,
            consentModalOpen: true,
          });
        }
      }
    );
  }

  componentDidMount() {
    const showConsentDetails = window.customrole.UserContext && window.customrole.UserContext.isLatestAgreementAccepted === null
    if (showConsentDetails) {
      this.getConsentDetails();
    }
  }
  updateConsentDetails(details) {
    http
      .post(`${Config.getAdminServiceURL}/api/consent/`, details)
      .then((response) => {
        if (details.isTrackingAllowed && response.data) {
          this.startHotJarTracking();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleUserSelectedOption(action) {
    if (action === "back") {
      this.setState({ changeContent: true });
    }
    if (action === "next") {
      this.setState({ changeContent: false });
    }
    if (action === "accept" || action === "decline") {
      this.setState({ consentModalOpen: false });
      this.updateConsentDetails({
        isTrackingAllowed: action === "accept" ? true : false,
        version: this.state.consentDetails.version,
      });
    }
  }

  render() {
    return (
    <ConsentFormModal
      size={"large"}
      open={this.state.consentModalOpen}
      duration={10}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      showBackdrop={true}
      handleUserSelectedOption={this.handleUserSelectedOption}
    >
      {this.state.changeContent ? (
        <div
          dangerouslySetInnerHTML={{
            __html: this.state.consentDetails?.termsOfUse,
          }}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: this.state.consentDetails?.privacyPolicy,
          }}
        />
      )}
    </ConsentFormModal>
    )
  }
}

export default ConsentFrom;
